<template>
    <section>

      <div class="columns is-gapless is-marginless is-centered is-mobile">
        <div class="column is-11 is-narrow is-mobile">
          <b-input v-model="inputCode" maxlength="20000"
              placeholder="" type="textarea" custom-class="myCustomInputTextsize">
          </b-input>

          <div class="columns is-gapless is-marginless is-mobile mb2rem">
            <b-field label="line-split" class="optionBoxWrap">
              <b-button @click="splitNewlineComma">,</b-button>
              <b-button @click="splitnewlineAppos">"</b-button>
              <b-button @click="splitnewlineTrim">trim</b-button>              
            </b-field>

            <b-field label="line-join" class="optionBoxWrap">
              <b-button @click="joinNewlineN">E</b-button>
              <b-button @click="joinNewlineS">Space</b-button>
              <b-button @click="joinNewlineComma">,</b-button>
              <b-button @click="joinNewlineCommaString">","</b-button>
            </b-field>
<!-- 
            <b-field label="toArray" class="optionBoxWrap">
              <b-button>"</b-button>
            </b-field> -->

            <b-field label="remove" class="optionBoxWrap">
              <b-button @click="removeCRLF">CRLF</b-button>
              <b-button @click="replaceApposChar">"</b-button>
              <b-button @click="replaceApposChar2">'</b-button>
              <b-button @click="replaceApposChar5">`</b-button>
              <b-button @click="replaceApposChar3">,</b-button>
              <b-button @click="replaceApposChar4">","</b-button>
              <b-button @click="replaceApposCharTabs">tabs</b-button>
              <b-button @click="removeCommentLines">#</b-button>
            </b-field>

            <b-field label="replace" class="optionBoxWrap">
              <b-button @click="replaceDotComma">.|,</b-button>
            </b-field>

            <b-field label="filter" class="optionBoxWrap">
              <b-button @click="filterDoubleSpaces">2x-Spaces</b-button>
              <b-button @click="filterDoubleComma">2x-Commas</b-button>
              <b-button @click="filterEmptyLines">empty Lines</b-button>

            </b-field>

            <b-field label="format" class="optionBoxWrap">
              <b-button @click="jsonFormatCode">json</b-button>
              <b-button @click="mysqlFormatCode">mysql</b-button>
              <b-button @click="xmlFormatCode">xml</b-button>
            </b-field>

            <b-field v-if="outputModeSelected === 'editor'" label="language" class="column is-1">
              <b-select v-model="languageSelected" placeholder="select language">
                <option v-for="option of languages" :value="option" :key="`editor_${option}`">
                  {{ option }}
                </option>
              </b-select>
            </b-field>

            <b-field label="mode" class="column is-1">
              <b-select v-model="outputModeSelected" placeholder="mode">
                <option v-for="option of outputModes" :value="option" :key="`mode_${option}`">
                  {{ option }}
                </option>
              </b-select>
            </b-field>

            <b-field label="actions" class="column is-2">
              <b-button @click="outputCodeToInputCode">^up^</b-button>
              <b-button @click="copyOutputToClipboard">copy</b-button>
            </b-field>
          </div>


          <b-input v-if="outputModeSelected === 'text'" v-model="code"
              custom-class="myCustomOutputTextsize"
              placeholder="" type="textarea">
          </b-input>
          <MonacoEditor v-if="outputModeSelected === 'editor'"  class="editor" v-model="code" :language="languageSelected" />

        </div>
      </div>


      <b-notification :closable="false">
      <b-loading :is-full-page="true" :active.sync="pageLoading"></b-loading>
      </b-notification>
    </section>
</template>

<script>
// import axios_api from '@/plugins/axios_api';
/* eslint-disable */
// import { WEB_ROOT, API_ROOT } from '@/config/app.js';
import jsonFormat from 'json-format';
import { format as mysqlFormat } from 'sql-formatter';
import xmlFormat from 'xml-formatter';
// import sqlFormatter from '@sqltools/formatter';
import MonacoEditor from 'vue-monaco'

// https://microsoft.github.io/monaco-editor/

export default {
  name: "editor",

  components: {
    MonacoEditor
  },

  data() {
    return {
      pageLoading         : false,
      pageLoadingTimeout  : null,

      inputCode: ``,

      languages: ["plaintext", "javascript", "html", "json", "mysql", "xml", "shell", "yaml"],
      languageSelected: "plaintext",

      outputModes: ["text", "editor"],
      outputModeSelected: "editor",

      code: ''
    }
  },

  mounted(){

  },
  methods: {

    splitNewlineComma(){
      this.code = this.inputCode.trim().split(`,`).join(`,\n`)
    },
    joinNewlineComma(){
      this.code = this.inputCode.trim().split(`\n`).join(`, `)
    },
    joinNewlineN(){
      this.code = this.inputCode.trim().split(`\n`).join(``)
    },
    joinNewlineS(){
      this.code = this.inputCode.trim().split(`\n`).join(` `)
    },
    joinNewlineCommaString(){
      let tmp = this.inputCode.trim().split(`\n`)
      tmp = tmp.map(l => `"${l.trim()}"`)
      this.code = tmp.join(`, `)
    },
    splitnewlineAppos(){
      this.code = this.inputCode.trim().split(`"`).join(`,\n`)
    },
    splitnewlineTrim(){
      let tmp = this.inputCode.trim().split(`\n`)
      tmp = tmp.map(l => l.trim())
      this.code = tmp.join(`\n`)
    },
    replaceApposCharTabs(){
      this.code = this.replaceChar(this.inputCode, `\t`, ` `)
    },
    replaceApposChar(){
      this.code = this.replaceChar(this.inputCode, `"`, ``)
    },
    replaceApposChar2(){
      this.code = this.replaceChar(this.inputCode, `'`, ``)
    },
    replaceApposChar5(){
      this.code = this.replaceChar(this.inputCode, `\``, ``)
    },
    replaceApposChar3(){
      this.code = this.replaceChar(this.inputCode, `,`, ``)
    },
    replaceApposChar4(){
      let tmp = this.inputCode.trim()
      tmp = this.replaceChar(tmp, `"`, ``)
      tmp = this.replaceChar(tmp, `,`, ``)
      this.code = tmp
    },
    replaceDotComma(){
      let tmp = this.inputCode.trim()
      tmp = this.replaceChar(tmp, `.`, `###DOT###`)
      tmp = this.replaceChar(tmp, `,`, `.`)
      tmp = this.replaceChar(tmp, `###DOT###`, `,`)
      this.code = tmp
    },
    replaceApposChar4(){
      let tmp = this.inputCode.trim()
      tmp = this.replaceChar(tmp, `"`, ``)
      tmp = this.replaceChar(tmp, `,`, ``)
      this.code = tmp
    },
    filterDoubleSpaces(){
      let tmp = this.inputCode.trim().split(` `)
      tmp = tmp.filter(str => str !== '')
      this.code = tmp.join(` `)
    },
    filterDoubleComma(){
      let tmp = this.inputCode.trim().split(`,`)
      tmp = tmp.filter(str => str.trim() !== '')
      this.code = tmp.join(`,`)
    },
    filterEmptyLines(){
      let tmp = this.inputCode.trim().split(`\n`)
      tmp = tmp.filter(str => str.trim() !== '')
      this.code = tmp.join(`\n`)
    },
    replaceChar(code, toRemoveChar, replacewithChar = ' '){
      return code.split(toRemoveChar).join(replacewithChar)
    },
    removeCRLF(){
      let tmp = this.inputCode.trim()
      tmp = tmp.split(`\n`)
      tmp = tmp.map(l => l.trim())
      tmp = tmp.join(` `)
      console.info(`tmp:`, tmp)
      this.code = tmp
    },
    removeCommentLines(){
      let lines = this.inputCode.trim().split(`\n`)
      let list = []
      for(let line of lines) {
        let check = line.toString().trim()
        if (check.length > 0 && line.substring(0, 1) === '#' && line.substring(0, 2) !== '#!') continue
        list.push(line)
      }
      this.code = list.join(`\n`)
    },
    // joinnewlineComma(){
    //   this.code = this.inputCode.split(`,`).join(`,\n`)
    // },
    // splitnewlineAppos(){
    //   this.code = this.inputCode.split(`"`).join(`,\n`)
    // },
    xmlFormatCode(){
      try {
        this.code = xmlFormat(this.inputCode.trim())
        this.languageSelected = 'xml'
      } catch (err) {
        console.info(`Error xmlFormat:`, err);
      }
    },
    mysqlFormatCode(){
      try {
        let tmp = mysqlFormat(this.inputCode.trim())
        tmp = tmp.split(`and `).join(`    AND `)
        tmp = tmp.split(`or `).join(`  OR `)
        tmp = tmp.split(`left join `).join(`LEFT JOIN `)
        tmp = tmp.split(`right join `).join(`RIGHT JOIN `)
        tmp = tmp.split(`inner join `).join(`INNER JOIN `)
        tmp = tmp.split(`order by`).join(`ORDER BY`)
        tmp = tmp.split(`group by`).join(`GROUP BY`)
        tmp = tmp.split(` on `).join(` ON `)
        tmp = tmp.split(` as `).join(` AS `)
        tmp = tmp.split(`join `).join(`JOIN `)
        this.code = tmp
        // this.code = sqlFormatter.format(this.inputCode.trim())
        this.languageSelected = 'mysql'
      } catch (err) {
        console.info(`Error mysqlFormatCode:`, err);
      }
    },
    jsonFormatCode(){
      try {
        console.info(`inputCode:`, this.inputCode.trim())
        console.info(`jsonFormat(this.inputCode):`, jsonFormat(JSON.parse(this.inputCode.trim())))
        this.code = jsonFormat(JSON.parse(this.inputCode.trim()))
        this.languageSelected = 'json'
      } catch (err) {
        console.info(`Error jsonFormat:`, err);
      }
    },
    outputCodeToInputCode(){
      this.inputCode = this.code
    },
    copyOutputToClipboard(){
      navigator.clipboard.writeText(this.code);
    },
    async ShowPageLoading(maxDuration = 30000){
      this.pageLoading = true;

      if (this.pageLoadingTimeout !== null) {
        clearTimeout(this.pageLoadingTimeout);
      }

      this.pageLoadingTimeout = setTimeout(() => {
        this.HidePageLoading();
      }, maxDuration);
    },

    async HidePageLoading() {
      this.pageLoading = false;
      if (this.pageLoadingTimeout !== null) {
        clearTimeout(this.pageLoadingTimeout);
      }
    },
  }
};
</script>

<style>
  .editor {
    width: 100%;
    height: 1200px;
  }
  
  .optionBoxWrap{
    float:left;
    margin-right: 25px;
  }

  textarea.myCustomInputTextsize{
    width: 100%;
    height: 400px;
  }
  textarea.myCustomOutputTextsize{
    width: 100%;
    height: 1200px;
  }
</style>